import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { graphql } from 'gatsby'

import Layout from 'components/layout'
import MetaTags from 'components/MetaTags'
import Section, { SectionBlock } from 'components/Section'
import Wrapper from 'components/Wrapper'
import Header from 'components/Header'
import Directions from 'components/Directions'

const Contact = ({ data }) => {
  const intl = useIntl()

  const {
    headerImage: {
      childImageSharp: { fluid },
    },
  } = data

  return (
    <Layout>
      <MetaTags title='Contact | Hotel Griffintown' />
      <Header
        eyebrow='Contact'
        heading={intl.formatMessage({ id: 'contact.heading' })}
        fluid={fluid}
      />
      <Section>
        <SectionBlock>
          <Wrapper>
            <Directions />
          </Wrapper>
        </SectionBlock>
      </Section>
    </Layout>
  )
}

export default Contact

export const query = graphql`
  query ContactPage {
    headerImage: file(name: { eq: "img-hero-contact" }) {
      childImageSharp {
        fluid(maxWidth: 2560, quality: 100, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
